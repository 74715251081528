<template>
  <div class="mx-auto" :class="content.cssClass">
    <div class="details-section">
      <div class="flex flex-col flex-1 w-auto pr-0">
        <!-- <div class="delay-200 opacity-0 details-section-callout" v-animate-onscroll="'animated fadeIn'">
          <body-copy :content="content.callout.content"/>
        </div> -->
        <h3 v-if="content.heading" class="opacity-0" v-animate-onscroll="'animated fadeIn'">{{this.$route.name=='birthdays'?'':content.heading}}</h3>
        <body-copy v-if="isLostPins && content.detailsColumnOne" :content="content.detailsColumnOne.content" class="text-center delay-200 opacity-0" v-animate-onscroll="'animated fadeIn'" />
        <h4 v-else-if="content.subheading" class="delay-200 opacity-0" v-animate-onscroll="'animated fadeIn'">{{content.subheading}}</h4>
        <div class="flex flex-wrap details-section-details" >
          <div v-if="$route.name === 'birthdays'" >
            <body-copy  :content="content.detailsColumnOne.content" class="flex w-full opacity-0 birthdays-detail" v-animate-onscroll="'animated fadeIn'" />
          </div>
          <body-copy v-if="!isLostPins && content.detailsColumnOne && this.$route.name!=='birthdays'" :content="content.detailsColumnOne.content" class="w-full opacity-0 lg:w-1/2 lg:px-2" v-animate-onscroll="'animated fadeIn'" />
          <body-copy v-if="content.detailsColumnTwo && this.$route.name!=='birthdays' " :content="content.detailsColumnTwo.content" class="w-full opacity-0 lg:w-1/2 lg:px-2" v-animate-onscroll="'animated fadeIn'"/>
          <div v-if="content.ctas">
            <div v-for="c in content.ctas" :key="c.index" class="w-full opacity-0" v-animate-onscroll="'animated fadeIn'">
              <a class="my-2 text-center purple button pulse" v-if="c.fields" :href="c.fields.url">
                {{c.fields.title}}
              </a>
            </div>
          </div>
          <div class="w-full opacity-0" v-animate-onscroll="'animated fadeIn'" v-else>
            <a class="my-2 text-center purple button pulse" v-if="content.cta && content.cta.fields" :href="content.cta.fields.url">
              {{content.cta.fields.title}}
            </a>
          </div>
        </div>
        <div class="image-section w-full flex align-center justify-center p-4 mb-4">
          <img class="w-full md:w-auto" v-if="image" :src="image" >
        </div>
        <div v-if="content.legal" class="opacity-0 details-section-legal" v-animate-onscroll="'animated fadeIn'">
          <body-copy :content="content.legal.content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BodyCopy from '../common/BodyCopy'
import DefaultPicture from '../common/DefaultPicture'
import BirthdaySection from '../common/BirthdaySection'

export default {
  components: {
    BodyCopy,
    DefaultPicture,
    BirthdaySection
  },
  props: [
    'content'
  ],
  computed: {
    isLostPins() {
      if (this.$route.path.match('/beaumont-lost-pins-winner') || this.$route.path.match('/beaumont-lost-pins-winner')) {
        return true
      } else {
        return false
      }
    },
    image() {
      if (this.$mq === 'sm' && this.content.mobileImage) {
        return this.content.mobileImage.fields.file.url
      } else {
        if (this.content.desktopImage) {
          return this.content.desktopImage.fields.file.url
        } else if (this.content.mobileImage) {
          return this.content.mobileImage.fields.file.url
        }
      }

      return null
    }
  },
  methods:{
      addCtaToArrows(){
        let cards = document.getElementsByClassName("card-packs");
        for (let index = 0; index < cards.length; index++) {
          let element =  cards[index].children;
          let h4
          for (let j = 0; j < element.length; j++) {
            if(element[j].nodeName=='H4'){
              h4=element[j];
            }
          }
          let arrowImg = document.createElement("img");
          arrowImg.className="arrow-down";
          arrowImg.id=index+1;
          arrowImg.src = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTFweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMTEgMjIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDExIDIyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiM4QTBBRkYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iDQoJMSwxIDEwLDExIDEsMjEgIi8+DQo8L3N2Zz4NCg==";
          arrowImg.addEventListener('click', function (e) {
              for (let k = 0; k < cards.length; k++) {
                if(e.currentTarget.id!=k+1){
                  let cards = document.getElementById(k+1+"_detail");
                  cards.classList.add("shown");
                }
              }
            document.getElementById(e.currentTarget.id+"_detail").classList.toggle('shown');
          });
          h4.appendChild(arrowImg);

        }
      }
  },
  async mounted() {
      this.addCtaToArrows();
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.nco-details {
  .flex {
    h3 {
      color: $purple;
    }

    a {
      width: 50%;
      margin: auto;
      color: black;
      background: transparent;

      @media (min-width: $sm) {
        width: 35%;
      }

      @media (min-width: $lg) {
        width: 20%;
      }
    }
  }
}

.careers {
  padding-top: 25px;
}
</style>

<style lang="scss">
@import '../../assets/scss/_variables';

.careers {
  position: relative;
  z-index: 10;
  background-position: top;
  background-repeat: no-repeat;
  background-color: white;
  .details-section-details {
    align-items: center;
    justify-content: center;
    div {
      @media (min-width: $md) {
        width: 66%!important;
      }
    }
    h1 {
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
      font-size: 36px;
      margin-bottom: 10px;
      line-height: 1.2;

      @media (max-width: $sm) {
        font-size: 26px;
        width: 300px;
        max-width: 100%;
        margin: auto;
        margin-bottom: 10px;
        padding: 0 27px;
      }
    }
  }
}
#educators {
  .details-section-details {
    p {
      text-align: center!important;
      font-weight: 600;
    }
  }
  h1 {
    width: 486px;
    max-width: 100%;
  }
}

.season-pass {
  .details-section-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: flex-start;
    }
    div {
      width: 75%;

      @media (min-width: $md) {
        width: 45%;
      }

      img {
        height: 200px;
        width: auto;
        margin-bottom: 10px !important;
      }

      p {
        text-align: center;
        font-weight: 600;
      }
    }
  }
}

.party-city-packages {
  margin-top: 50px;
  div {
    .details-section-details {
      margin-top: 15px;
      align-items: flex-start;
      justify-content: space-between;
      div {
        .pc-package {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin: 20px 0;

          @media (min-width:$md) {
            height: 280px;
            flex-direction: row-reverse;
            align-items: flex-start;
            justify-content: space-between;
          }

          p {
            margin: 0!important;
          }

          img {
            width: 100%;
            margin: 0!important;
            @media (min-width: $md) {
              width: 47.5%
            }
          }

          div {
            width: 100%;
            @media (min-width: $md) {
              width: 47.5%
            }

            h4 {
              font-size: 20px;
              text-align: center;
              margin-bottom: 5px;

              @media (min-width: $md) {
                text-align: right;
              }
            }

            p {
              text-align: center;

              @media (min-width: $md) {
                text-align: right;
              }
            }
          }
        }

        &:nth-of-type(2) {
          .pc-package {
            @media (min-width:$md) {
              flex-direction: row;
            }
            div {
              h4 {
                @media (min-width: $md) {
                  text-align: left;
                }
              }

              p {
                @media (min-width: $md) {
                  text-align: left;
                }
              }
            }
          }
        }

        &:nth-of-type(3) {
          margin-bottom: 0;
        }
      }
    }
    .details-section-legal {
      width: 100%;
      p {
        font-size: 13px;
        font-weight: 100;
        text-align: center;
        margin-top: 18px;
      }
    }
  }
}

.pi-day-challenge {
  div {
    .details-section {
      @media (max-width: $md) {
        margin: 20px 0;
      }
      h3 {
        font-size: 16px;
        line-height: 26px;
        max-width: 75%;
        margin: auto;
        color: white;
        @media (min-width: $md) {
          font-size: 20px;
          line-height: 30px;
        }
      }

      h4 {
        font-size: 16px;
        color: white;
        font-weight: normal;
        @media (min-width: $md) {
          font-size: 20px;
        }
      }
      .details-section-details {
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: auto;
          max-width: 100%;

          @media (min-width: $md) {
            max-width: 75%;
          }

          img {
            width: 25%;
            @media (min-width: $lg) {
              width: 15%;
            }
          }
        }

        a {
          width: 50%;
          margin: auto;
          margin-top: 15px;
          color: $purple;
          background: white;

          @media (min-width: $sm) {
            width: 35%;
          }

          @media (min-width: $lg) {
            width: 20%;
          }
        }
      }
    }
    .details-section-legal {
      margin-top: 50px;
      p {
        color: white;
        text-align: center;
      }
    }
  }
}

.escape-room-section-details {
  .details-section-details {
    h2 {
      font-family: "urw-din", 'Helvetica Neue', Arial, sans-serif;
      font-weight: 800;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
}
</style>
